import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import ProductGrid from '../product-grid/product-grid';

const featuredStaticQuery = graphql`
	query {
		allErpItem(limit: 12) {
			edges {
				node {
					id
					item_code
					item_name
					description
					creation
					item_group
					is_sales_item
					last_purchase_rate
					localImage {
						childImageSharp {
							gatsbyImageData(
								aspectRatio: 1.25,
								placeholder: TRACED_SVG,
								transformOptions: {fit: CONTAIN},
								backgroundColor: "transparent"
							)
						}
					}
					slug
					stock_uom
				}
			}
		}
	}
`;

const FeaturedProductsHome: React.FC<{ withLink?: boolean }> = ({
	withLink = true,
}) => (
	<StaticQuery<GatsbyTypes.Query>
		query={`${featuredStaticQuery}`}
		render={({ allErpItem }) => {
			const products = allErpItem?.edges;
			return (
				<ProductGrid
					id="feature"
					withLink={withLink}
					gridTitle="Productos Destacados"
					products={products}
				/>
			);
		}}
	/>
);

export default FeaturedProductsHome;
