import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import get from 'lodash/get';
import SEO from '../components/seo';
import PrimaryLayout from '../components/layout/primary/primary';
import HomeBanner from '../components/home-banner/home-banner';
import HowItWorks from '../components/how-it-works/secondary/secondary';
import CategoryBlocks from '../components/category-blocks/secondary/secondary';
import FeaturedProducts from '../components/featured-products/featured-products-home';
import AdBanner from '../components/ad-banner/ad-banner';
import TrendingProducts from '../components/trending-products/trending-products-home';
import CallusBanner from '../components/call-us-banner/call-us-banner';

const indexPageStaticQuery = graphql`
	query {
		prismicHomeminimal {
			data {
				banner_title {
					raw
				}
				banner_subtitle {
					raw
				}
				banner_button_text {
					raw
				}
				banner_image {
					url
				}
				how_it_works {
					thumbnail {
						url
					}
					title {
						raw
					}
					short_description {
						raw
					}
				}
				call_us_banner {
					url
				}
				call_us_banner_content {
					raw
				}
				call_us_button_text {
					raw
				}
			}
		}
		prismicHome {
			data {
				category_section_title {
					raw
				}
				category_section_short_intro {
					raw
				}
				category_block {
					category_slug
					category_title {
						raw
					}
					image {
						fluid {
							...GatsbyPrismicImageFluid_withWebp_noBase64
						}
					}
				}
			}
		}
		prismicCommon {
			data {
				ad_blocks {
					badge_title {
						raw
					}
					badge_color
					title {
						raw
					}
					block_image {
						url
					}
					short_description {
						raw
					}
					add_a_button
					button_title {
						raw
					}
				}
			}
		}
	}
`;

const IndexPage: React.FC<{}> = () => (
	<StaticQuery<GatsbyTypes.Query>
		query={`${indexPageStaticQuery}`}
		render={(data) => {
			const categorySectionTitle = data.prismicHome?.data?.category_section_title?.raw;
			const categorySectionIntro = data.prismicHome?.data?.category_section_short_intro?.raw;
			const categoryItems = data.prismicHome?.data?.category_block;
			
			const node = data.prismicHomeminimal?.data;
			const adBlocks = data.prismicCommon?.data?.ad_blocks;
			const bannerImage = node?.banner_image?.url;
			const bannerTitle = node?.banner_title;
			const bannerSubTitle = node?.banner_subtitle;
			const bannerButtonText = node?.banner_button_text;
			const howItworks = node?.how_it_works;
			const callUsBanner = node?.call_us_banner;
			const callUsBannerContent = node?.call_us_banner_content?.raw;
			const callUsButtonText = node?.call_us_button_text?.raw;

			return (
				<PrimaryLayout
					fluid={true}
					showNoticeBar={false}
				>
					<SEO title="Home" />
					<HomeBanner
						banner={bannerImage}
						bannerTitle={bannerTitle}
						bannerSubTitle={bannerSubTitle}
						bannerButtonText={bannerButtonText}
					/>
					<HowItWorks items={howItworks} />
					<CategoryBlocks
						title={categorySectionTitle}
						shortIntro={categorySectionIntro}
						categoryItems={categoryItems}
					/>
					<FeaturedProducts withLink={true} />
					<AdBanner
						data={adBlocks}
						scrollTo="#trendingProducts"
						scrollOffset={60}
					/>
					<TrendingProducts withLink={true} />
					<CallusBanner
						callUsBanner={callUsBanner}
						callUsTitle={callUsBannerContent}
						callUsButtonText={callUsButtonText}
					/>
				</PrimaryLayout>
			);
		}}
	/>
);

export default IndexPage;
